import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Copy from 'components/category/copy'
import Content from 'components/category/content'
import AdditionalResources from 'components/new/additional-resources'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const items = [
  {
    link: '/lawn-garden/compact-tractors/1-family/',
    imageId: 'oneFamilyImage',
    name: '1-Family (23-25 HP)',
    alt: 'John Deere 1-Family Tractors',
  },
  {
    link: '/lawn-garden/compact-tractors/2-family/',
    imageId: 'twoFamilyImage',
    name: '2-Family (25-38 HP)',
    alt: 'John Deere 2-Family Tractors',
  },
  {
    link: '/lawn-garden/compact-tractors/3-family/',
    imageId: 'threeFamilyImage',
    name: '3-Family (25-46 HP)',
    alt: 'John Deere 3-Family Tractors',
  },
  {
    link: '/lawn-garden/compact-tractors/4-family/',
    imageId: 'fourFamilyImage',
    name: '4-Family (44-66 HP)',
    alt: 'John Deere 4-Family Tractors',
  },
  {
    link: '/lawn-garden/compact-tractors/hutson-tractor-packages/',
    imageId: 'tractorPackageImage',
    name: 'Hutson Tractor Packages',
    alt: 'Hutson Tractor Packages',
  },
]

const CompactTractorsPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Compact Tractors | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson has a full range of John Deere compact utility tractors for sale. Ranging from 23 horsepower all the way to 66 horsepower. These are the best sub compact tractors in the market.'
        />
        <meta
          name='keywords'
          content='Compact Tractors, Small Tractors, Sub Compact Tractors, Garden Tractors'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Lawn & Garden Equipment',
                'item': 'https://www.hutsoninc.com/lawn-garden/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Compact Tractors',
                'item': 'https://www.hutsoninc.com/lawn-garden/compact-tractors/',
              },
            ],
          })}
        </script>
      </Helmet>
      <HeroGradient image={heroImage} heading='Compact Tractors' />
      <Content>
        <Floater items={gridItems} />
        <Copy header='John Deere Compact Tractors'>
          <p>
            If you're looking for a sub-compact tractor, you're in the right place. Hutson has a
            full line up, carrying{' '}
            <Link to='/lawn-garden/compact-tractors/1-family/'>1-series</Link>,{' '}
            <Link to='/lawn-garden/compact-tractors/2-family/'>2-series</Link>,{' '}
            <Link to='/lawn-garden/compact-tractors/3-family/'>3-series</Link>, and{' '}
            <Link to='/lawn-garden/compact-tractors/4-family/'>4-series</Link> John Deere tractors
            ranging from 23 to 66 HP. These tractors are for those who work the land and want to
            make their larger properties better looking and more maintainable. With a wide range of
            models available, you're sure to find a suitable machine to meet all your needs -
            whether you're a property owner, landscape contractor, small-scale farmer, equine
            operator or just someone who likes to play with equipment every now and then.
          </p>
        </Copy>
        <Promos data={promos} type='Compact Tractor' />
        <Videos type='Compact Tractor' id='XNWGBvunIqo' list='PLUz5fj7f_mw85wNvn_wFTeHGP_qteIb_F' />
      </Content>
      <AdditionalResources
        resources={[
          // {
          //   title: 'Shopping Tools',
          //   links: [<OutboundLink href='https://build.hutsoninc.com/compact-tractors/'>Build Your Own</OutboundLink>],
          // },
          {
            title: 'Product Brochures',
            links: [
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=f569bdab'>
                View 1-Series Compact Tractors Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=849f923c'>
                View 2-Series Compact Tractors Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=116932e2'>
                View 3-Series Compact Tractors Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=2f7bcbe9'>
                View 4-Series Compact Tractors Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=49142322'>
                View Compact Tractor Implements &amp; Attachments Brochure
              </OutboundLink>,
              <OutboundLink href='https://www.deere.com/assets/publications/index.html?id=a057c13c'>
                View Frontier&trade; Full-Line Brochure
              </OutboundLink>,
            ],
          },
          {
            title: 'Offers & Discounts',
            links: [
              <Link to='/promotions/john-deere/compact-tractors/'>View Current Promotions</Link>,
            ],
          },
        ]}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query compactTractorsSubcategoryQuery($subcategory: String = "compact-tractors") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/compact-tractors-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    oneFamilyImage: file(relativePath: { eq: "lawn-and-garden/1-family.jpg" }) {
      ...FloatingGridImage
    }
    twoFamilyImage: file(relativePath: { eq: "lawn-and-garden/2-family.jpg" }) {
      ...FloatingGridImage
    }
    threeFamilyImage: file(relativePath: { eq: "lawn-and-garden/3-family.jpg" }) {
      ...FloatingGridImage
    }
    fourFamilyImage: file(relativePath: { eq: "lawn-and-garden/4-family.jpg" }) {
      ...FloatingGridImage
    }
    tractorPackageImage: file(relativePath: { eq: "lawn-and-garden/1025r-tlb.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default CompactTractorsPage
